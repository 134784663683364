const dateHelper = {
  toDuration(time) {
    const hour = Math.floor(time / 3600);
    const minutes = Math.floor((time - hour * 3600) / 60);
    const seconds = time - hour * 3600 - minutes * 60;

    return `${hour ? `${hour}h` : ''}${minutes ? `${minutes}m` : ''}${
      seconds ? `${seconds}s` : ''
    }`;
  }
};

export default dateHelper;
