import React from 'react';
import { CardActions, List, ListItem, RaisedButton } from 'material-ui';
import ArrowRight from 'material-ui/svg-icons/hardware/keyboard-arrow-right';
import counterpart from 'counterpart';
import { connect } from 'react-redux';
import { compose, lifecycle, withHandlers, withState } from 'recompose';
import classnames from 'classnames';
import { addAnswer, nextQuestion } from '../../actions/tests';

function AnswerMultipleList({ answers, lockAndNext, onKeyPressed, possibleAnswers }) {
  return (
    <div>
      <List>
        {possibleAnswers.map((answer, index) => (
          <ListItem
            className={classnames('answer', {
              'answer--active': answers.includes(answer['@id'])
            })}
            leftIcon={<span className="letter">{String.fromCharCode(97 + index)}</span>}
            primaryText={answer.value}
            key={answer['@id']}
            onClick={() => onKeyPressed({ keyCode: 65 + index })}
          />
        ))}
      </List>
      <CardActions style={{ textAlign: 'right' }}>
        <span className="help-multiple">{counterpart.translate('question.severalAnswers')}</span>
        <RaisedButton
          label={counterpart.translate('question.nextQuestion')}
          labelPosition="before"
          labelStyle={{ verticalAlign: 'middle' }}
          icon={<ArrowRight />}
          disabled={answers.length < 2}
          onClick={lockAndNext}
        />
      </CardActions>
    </div>
  );
}

const withKeyboardListener = lifecycle({
  componentDidMount() {
    if (this.props.position === 1) {
      document.body.addEventListener('keydown', this.props.onKeyPressed);
    }
  },
  componentWillReceiveProps({ active }) {
    if (this.props.active !== active) {
      if (active === true) {
        this.props.changeReadOnlyStatus(() => false);
        document.body.addEventListener('keydown', this.props.onKeyPressed);
      } else {
        document.body.removeEventListener('keydown', this.props.onKeyPressed);
      }
    }
  }
});

const withEventsManagement = withHandlers({
  onKeyPressed:
    ({ add, possibleAnswers, readonly }) =>
    (e) => {
      const keyPressed = e.keyCode - 65;

      if (!e.ctrlKey) {
        if (!readonly && keyPressed >= 0 && keyPressed < possibleAnswers.length) {
          add(possibleAnswers[keyPressed]['@id']);
        } else {
          e.preventDefault();
          e.stopPropagation();
        }
      }
    },
  lockAndNext:
    ({ changeReadOnlyStatus, next }) =>
    () => {
      changeReadOnlyStatus(() => true);
      next();
    }
});

const withReadOnly = withState('readonly', 'changeReadOnlyStatus', false);

const mapDispatchToProps = (dispatch, { answers }) => ({
  add: (answer) => {
    let newAnswers;
    if (typeof answers === 'undefined') {
      newAnswers = [answer];
    } else if (answers.includes(answer)) {
      newAnswers = answers.filter((value) => answer !== value);
    } else {
      newAnswers = [...answers, answer];
    }

    dispatch(addAnswer(newAnswers, false));
  },
  next: () => dispatch(nextQuestion())
});

AnswerMultipleList.defaultProps = {
  answers: []
};

const enhance = compose(
  connect(undefined, mapDispatchToProps, null, { pure: false }),
  withReadOnly,
  withEventsManagement,
  withKeyboardListener
);

AnswerMultipleList.defaultProps = {
  answers: [],
  possibleAnswers: []
};

export default enhance(AnswerMultipleList);
