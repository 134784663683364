import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
// TODO: remove this comment everywhere by fixing it
// eslint-disable-next-line import/no-cycle
import { Progression, QuestionList } from './index';
import Summary from '../summary';
import { prevQuestion, nextQuestion } from '../../actions/tests';

function QuizzContainer() {
  return (
    <div className="quizz-container">
      <Progression />
      <QuestionList />
      <Summary />
    </div>
  );
}

const keyPressed = (e, { done, previous, next }) => {
  if (!done) {
    if (e.keyCode === 37 || e.keyCode === 38) {
      // up arrow
      previous();
    } else if (e.keyCode === 39 || e.keyCode === 40) {
      // down arrow
      next();
    }
  }
};

const withKeyboardListener = lifecycle({
  componentDidMount() {
    document.body.addEventListener('keydown', (e) => keyPressed(e, this.props));
  }
});

const mapStateToProps = ({ tests }) => ({
  done: tests.test.done
});

const mapDispatchToProps = (dispatch) => ({
  previous: () => dispatch(prevQuestion()),
  next: () => dispatch(nextQuestion())
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withKeyboardListener);

export default enhance(QuizzContainer);
