import React from 'react';
import { AppBar, Card, CardActions, List, ListItem, RaisedButton } from 'material-ui';
import TrendingUp from 'material-ui/svg-icons/action/trending-up';
import TrendingDown from 'material-ui/svg-icons/action/trending-down';
import { GridList, GridTile } from 'material-ui/GridList';
import CircularProgress from 'material-ui/CircularProgress';
import { brown50 } from 'material-ui/styles/colors';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import classnames from 'classnames';
import counterpart from 'counterpart';
import dateHelper from '../../lib/dateHelper';
import { gotoQuestion, saveResults } from '../../actions/tests';
import '../../../stylesheets/components/summary.scss';
import EntityHelper from '../../lib/entityHelper';

const { Prism } = window;

const prepareForPrism = (code = '', language) => {
  return code
    .replace(/<pre>/gi, `<pre><code class="language-${language}">`)
    .replace(/<\/pre>/gi, '</code></pre>');
};

// Hooks Prism to allow <br />
Prism.hooks.add('before-highlight', (env) => {
  // eslint-disable-next-line no-param-reassign
  env.code = env.element.innerText;
});

function Overlay() {
  return (
    <div className="overlay">
      <RaisedButton label={counterpart.translate('summary.edit')} backgroundColor={brown50} />
    </div>
  );
}

const Score = ({ count, score, multiple, cols, top = 50 }) => {
  const [size, thickness] = multiple ? [75, 6] : [150, 12];
  return (
    <GridTile cols={cols} className="question-chart">
      <CircularProgress
        style={{ top: `${top}%` }}
        mode="determinate"
        color="#ffffff40"
        size={size}
        thickness={thickness}
        value={100}
      />
      <CircularProgress
        style={{ top: `${top}%` }}
        mode="determinate"
        color="white"
        size={size}
        thickness={thickness}
        value={score}
      />
      <div
        className={`question-chart-value${multiple ? ' multiple' : ''}`}
        style={{ top: `${top}%` }}
      >
        {score}%<div className="count">{count}</div>
      </div>
    </GridTile>
  );
};

const SummaryText = ({ cols, score, isRecruiting, progressionScore }) => {
  return (
    <GridTile cols={cols}>
      <h3>{counterpart.translate('summary.thanks.title')}</h3>
      <p>
        {counterpart.translate('summary.thanks.score')} {score}%.
      </p>
      <p>{isRecruiting && counterpart.translate('summary.thanks.openingRecruiting')}</p>
      <p>
        {!isRecruiting &&
          !progressionScore &&
          counterpart.translate('summary.thanks.openingTrainingPre')}
      </p>
      <p>
        {!isRecruiting &&
          progressionScore &&
          counterpart.translate('summary.thanks.openingTrainingPost')}
      </p>
      <p className="small">{counterpart.translate('summary.thanks.rgpd')}</p>
    </GridTile>
  );
};
const iconStyles = {
  position: 'absolute',
  height: '475%',
  width: '475%',
  overflow: 'visible',
  color: '#ffffff28'
};

const ProgressIcon = ({ progressionScore }) => {
  return progressionScore >= 0 ? (
    <TrendingUp style={iconStyles} />
  ) : (
    <TrendingDown style={iconStyles} />
  );
};

const Summary = ({
  active,
  answers,
  goto,
  save,
  test,
  score,
  previousScore,
  progressionScore,
  previousCount,
  tops,
  count
}) => {
  return (
    <Card
      className={classnames('card summary', {
        'card--active': active,
        'card--readonly': test.done
      })}
    >
      {!test.done && (
        <AppBar
          title={counterpart.translate('summary.title')}
          showMenuIconButton={false}
          titleStyle={{ paddingTop: '10px', paddingBottom: '10px' }}
        />
      )}
      <List style={{ overflowY: 'auto' }}>
        {test.done && test.previousTestInstance === null && (
          <div className="summary-thanks">
            <GridList cols={3} className="congratulations">
              <Score count={count} score={score} />
              <SummaryText isRecruiting={test.recruiting} score={score} cols={2} />
            </GridList>
          </div>
        )}
        {test.done && test.previousTestInstance !== null && (
          <div className="summary-thanks">
            <GridList cols={24} className="congratulations">
              <Score
                count={previousCount}
                score={previousScore}
                progressionScore={progressionScore}
                top={tops.previousTop}
                multiple
                cols={3}
              />
              <GridTile
                cols={2}
                className="question-chart-evolution"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'visible'
                }}
              >
                <ProgressIcon progressionScore={progressionScore} />
              </GridTile>
              <Score count={count} score={score} multiple cols={3} top={tops.currentTop} />
              <SummaryText
                isRecruiting={test.recruiting}
                score={score}
                cols={16}
                progressionScore={progressionScore}
              />
            </GridList>
          </div>
        )}
        {answers.map((answer, index) => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <div
            onClick={() => !test.done && goto(index + 1)}
            key={answer.label}
            className="list-item"
          >
            <div className="list-inline">
              <div style={{ verticalAlign: 'middle', width: '80%' }}>
                <ListItem primaryText={answer.label} secondaryText={answer.element} />
              </div>
              <div
                style={{
                  width: '15%',
                  verticalAlign: 'middle',
                  textAlign: 'right',
                  fontSize: '1.2rem'
                }}
              >
                {/* TODO: replace by a ship cmp from recent material-ui (after upgrading) that can be resized */}
                <span
                  style={{
                    padding: '2px 7px',
                    border: '1px solid rgba(0, 0, 0, 0.54)',
                    borderRadius: '50px',
                    color: 'rgba(0, 0, 0, 0.54)'
                  }}
                >
                  {answer.level}
                </span>
              </div>
              {!test.done && <Overlay />}
            </div>
          </div>
        ))}
      </List>
      {!test.done && (
        <CardActions style={{ padding: '25px', textAlign: 'right' }}>
          <RaisedButton
            label={counterpart.translate('summary.submit')}
            onClick={() => save(test)}
            primary
          />
        </CardActions>
      )}
    </Card>
  );
};

const Answers = ({ result, children, test }) => {
  return <p className={classnames({ wrong: test.done && !result.correct })}>{children}</p>;
};

const RightAnswers = ({ answersElement, answers }) => {
  const rightAnswers = answers.filter((answer) => answer.correct).map((answer) => answer.value);
  const count = rightAnswers.length;

  return (
    <div>
      {answersElement}
      <p className="right new-line">
        {counterpart.translate('summary.rightAnswers', { count })}
        <br />
        {rightAnswers.join('\n')}
      </p>
    </div>
  );
};

const AnswerBlock = ({ question, result, children }) => {
  return (
    <div className="answer-block">
      {question.help && (
        <div
          dangerouslySetInnerHTML={{
            __html: prepareForPrism(
              EntityHelper.encodePre(question.help),
              question.category.label.toLowerCase()
            )
          }}
        />
      )}
      {children}
      <p className="duration">
        {counterpart.translate('summary.duration')} {dateHelper.toDuration(result?.duration)}
      </p>
    </div>
  );
};

const getUserAnswers = (question, answersIds) => {
  const answersIdsArray = Array.isArray(answersIds) ? answersIds : [answersIds];
  return answersIdsArray
    .map((id) => question.answers.find((answer) => id === answer['@id'])?.value)
    .join(', ');
};

const getAnswersNode = (question, test, result, answersIds) => {
  const userAnswers = getUserAnswers(question, answersIds);

  let answersElement = (
    <Answers result={result} test={test}>
      {userAnswers}
    </Answers>
  );

  if (!test.done) return answersElement;

  if (!result.correct) {
    answersElement = <RightAnswers answersElement={answersElement} answers={question.answers} />;
  }

  return (
    <AnswerBlock question={question} result={result}>
      {answersElement}
    </AnswerBlock>
  );
};

const getAnswersElements = (test) => {
  return test.questions.map((question, index) => {
    const defaults = {
      label: question.label,
      level: counterpart.translate(['summary', 'levels', question.level.label])
    };
    const answersIds = test.results?.[index]?.answers;
    if (!answersIds?.length) {
      return defaults;
    }

    const result = test.results[index];
    return {
      ...defaults,
      element: getAnswersNode(question, test, result, answersIds)
    };
  });
};

const getScore = (test) => {
  if (test?.results === undefined) return;
  const rightAnswers = test.results.filter((result) => result.correct);
  return Math.round((rightAnswers.length * 100) / test.questions.length);
};

const getEvolutionScore = (score, previousScore) => {
  if (score === undefined || previousScore === undefined) return;
  return Math.round(((score - previousScore) / Math.max(previousScore, score)) * 100);
};

const getCount = (test) => {
  if (test?.results === undefined) return;
  const rightAnswersCount = test.results.filter((result) => result.correct).length;
  return `${rightAnswersCount} / ${test.questions.length}`;
};

const isTestActive = (test) =>
  test.done || (test.questions !== undefined && test.questions.length + 1 === test.currentQuestion);

const percentToRange = (percent = 50, R2 = 77, R1 = 23, M2 = 100, M1 = 0) =>
  100 - ((percent * (R2 - R1)) / (M2 - M1) + (R1 - M1));

const getTops = (progressionScore) => {
  const previousPercent = 50 - progressionScore / 2;
  const currentPercent = 50 + progressionScore / 2;
  const previousTop = percentToRange(previousPercent);
  const currentTop = percentToRange(currentPercent);
  return { currentTop, previousTop };
};

const mapStateToProps = ({ tests }) => {
  const { test } = tests;

  const defaults = {
    active: isTestActive(test),
    test,
    answers: [],
    score: 0,
    tops: {}
  };

  if (test.questions === undefined || !test.results.length) {
    return defaults;
  }

  const score = getScore(test);
  const previousScore = getScore(test.previousTestInstance);
  const progressionScore = getEvolutionScore(score, previousScore);
  const tops = getTops(progressionScore);

  return {
    ...defaults,
    answers: getAnswersElements(test),
    score,
    count: getCount(test),
    previousCount: getCount(test.previousTestInstance),
    previousScore,
    progressionScore,
    tops
  };
};

const mapDispatchToProps = (dispatch) => ({
  goto: (index) => dispatch(gotoQuestion(index)),
  save: (results) => dispatch(saveResults(results))
});

const withPrismRefresh = lifecycle({
  componentDidUpdate() {
    Prism.highlightAll();
  }
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps, null, { pure: false }),
  withPrismRefresh
);

export default enhance(Summary);
