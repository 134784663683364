import React from 'react';
import { Route, Switch } from 'react-router-dom';

import WelcomeMessage from '../welcome/WelcomeMessage';
import { QuizzContainer } from '../quizz';
import Error404 from './Error404';

function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={WelcomeMessage} />
      <Route path="/quizz" component={QuizzContainer} />
      <Route path="/404" component={Error404} />
      <Route path="*" component={Error404} />
    </Switch>
  );
}

export default Routes;
