import axios from '../lib/axios';

class TestResource {
  static entrypoint = '/test_instances';

  findByHash(hash) {
    return axios
      .get(`${TestResource.entrypoint}/${hash}`)
      .then((response) => response.data)
      .then(this.decodeResults);
  }

  saveResults(results) {
    return axios
      .put(results['@id'], this.encodeResults(results), {
        headers: {
          'Content-Type': 'application/ld+json'
        }
      })
      .then((response) => response.data)
      .then(this.decodeResults);
  }

  decodeResults(response) {
    return {
      ...response,
      results: response.results.map((answer, index) => {
        let answers = '';

        switch (response.questions[index].type) {
          case 'boolean':
          case 'simple':
            answers = answer.answers[0]['@id'];
            break;
          default:
            answers = answer.answers.map((item) => item['@id']);
        }

        return {
          answers,
          correct: answer.correct,
          duration: answer.duration
        };
      })
    };
  }

  encodeResults(results) {
    return {
      results: results.results.map((answer, index) => {
        const node = {
          question: results.questions[index]['@id'],
          duration: Math.round(answer.duration / 1000)
        };

        switch (results.questions[index].type) {
          case 'boolean':
          case 'simple':
            node.answers = [answer.answers];
            break;
          default:
            node.answers = answer.answers;
        }

        return node;
      })
    };
  }
}

const resource = new TestResource();
export default resource;
