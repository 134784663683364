import { combineReducers } from 'redux';

import testsReducer from './tests';

// -----------------------------------------------------------------------------
// REDUCER

const rootReducer = combineReducers({
  tests: testsReducer
});

// -----------------------------------------------------------------------------
// REDUCER EXPORT

export default rootReducer;
