import React from 'react';
import '../../../stylesheets/components/loading.scss';

function Loading() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 336.56 444.56"
      className="loading"
      role="img"
      aria-label="Les-Tilleuls.coop logo arbre"
    >
      <g id="tree" data-name="Tree">
        <path
          d="M336.56,168.28C336.56,75.49,261.07,0,168.28,0S0,75.49,0,168.28c0,77.34,52.45,142.65,123.64,162.26V297A136.51,136.51,0,0,1,32,168.28C32,93.13,93.13,32,168.28,32S304.56,93.13,304.56,168.28c0,70.07-53.17,128-121.28,135.45V274.67a97,97,0,0,0,67.79-79,35.67,35.67,0,0,0,9-3.2,30.49,30.49,0,0,0,12.36-11.71A33.76,33.76,0,0,0,277,163.13a34.12,34.12,0,0,0-4.48-17.64,30.19,30.19,0,0,0-12.25-11.7,39.27,39.27,0,0,0-34.86,0,30.32,30.32,0,0,0-12.25,11.7,37.32,37.32,0,0,0,0,35.33,30,30,0,0,0,12.13,11.65,33.77,33.77,0,0,0,5.72,2.37,76.93,76.93,0,0,1-47.66,58.65V142c.76-.33,1.52-.68,2.25-1.06a30.56,30.56,0,0,0,12.37-11.7,33.86,33.86,0,0,0,4.53-17.64A34.24,34.24,0,0,0,198,94a30.32,30.32,0,0,0-12.25-11.7,39.36,39.36,0,0,0-34.86,0A30.25,30.25,0,0,0,138.6,94a34.15,34.15,0,0,0-4.48,17.64,34.49,34.49,0,0,0,4.42,17.69A30,30,0,0,0,150.67,141c.85.45,1.72.84,2.61,1.22v76.41a53.32,53.32,0,0,1-49.16-38.25,35.31,35.31,0,0,0,7.6-2.87,30.58,30.58,0,0,0,12.37-11.71,33.85,33.85,0,0,0,4.53-17.63,34.21,34.21,0,0,0-4.47-17.64,30.32,30.32,0,0,0-12.25-11.7,36.94,36.94,0,0,0-17.43-4.08A36.91,36.91,0,0,0,77,118.79a30.19,30.19,0,0,0-12.25,11.7,34.12,34.12,0,0,0-4.48,17.64,34.39,34.39,0,0,0,4.42,17.69,30,30,0,0,0,12.13,11.65,33.75,33.75,0,0,0,6.6,2.62,73.35,73.35,0,0,0,69.82,58.5v206h30V335.88C269.07,328.27,336.56,256,336.56,168.28Zm-103.51-17.2a13.48,13.48,0,0,1,19.49,0q3.95,4.2,3.94,12t-4.06,12a13.21,13.21,0,0,1-9.83,4.19,12.46,12.46,0,0,1-9.6-4.19q-3.83-4.2-3.83-12T233.05,151.08Zm-148.36-15a13.48,13.48,0,0,1,19.49,0q3.94,4.2,3.95,12t-4.06,12a13.22,13.22,0,0,1-9.84,4.19,12.46,12.46,0,0,1-9.6-4.19q-3.82-4.2-3.83-12T84.69,136.08ZM158.5,99.57a13.51,13.51,0,0,1,19.5.06q3.93,4.19,3.94,12t-4.06,12A13.18,13.18,0,0,1,168,127.8a12.46,12.46,0,0,1-9.6-4.19q-3.83-4.19-3.82-12T158.5,99.57Z"
          pathLength="1"
          className="fill-current"
        />
      </g>
    </svg>
  );
}

export default Loading;
