import React from 'react';
import { Card, CardText } from 'material-ui';
import Translate from 'react-translate-component';
import { red800 } from 'material-ui/styles/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

function Error404() {
  return (
    <Card className="card card--active card-404">
      <CardText style={{ textAlign: 'center' }}>
        <FontAwesomeIcon icon={faExclamationTriangle} className="fa-5x" style={{ color: red800 }} />
        <Translate content="error.description" component="p" />
      </CardText>
    </Card>
  );
}

export default Error404;
