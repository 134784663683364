import React from 'react';
import { List, ListItem } from 'material-ui';
import { connect } from 'react-redux';
import { compose, lifecycle, withHandlers, withState } from 'recompose';
import classnames from 'classnames';
import { addAnswer } from '../../actions/tests';

function AnswerSingleList({ answers, onKeyPressed, possibleAnswers }) {
  return (
    <List>
      {possibleAnswers.map((answer, index) => (
        <ListItem
          className={classnames('answer', {
            'answer--active': answers === answer['@id']
          })}
          leftIcon={<span className="letter">{String.fromCharCode(97 + index)}</span>}
          primaryText={answer.value}
          key={answer['@id']}
          onClick={() => onKeyPressed({ keyCode: 65 + index })}
        />
      ))}
    </List>
  );
}

const withKeyboardListener = lifecycle({
  componentDidMount() {
    if (this.props.position === 1) {
      document.body.addEventListener('keydown', this.props.onKeyPressed);
    }
  },
  componentWillReceiveProps({ active }) {
    if (this.props.active !== active) {
      if (active === true) {
        this.props.changeReadOnlyStatus(() => false);
        document.body.addEventListener('keydown', this.props.onKeyPressed);
      } else {
        document.body.removeEventListener('keydown', this.props.onKeyPressed);
      }
    }
  }
});

const withEventsManagement = withHandlers({
  onKeyPressed:
    ({ add, changeReadOnlyStatus, possibleAnswers, readonly }) =>
    (e) => {
      const keyPressed = e.keyCode - 65;

      if (!e.ctrlKey && !readonly && keyPressed >= 0 && keyPressed < possibleAnswers.length) {
        changeReadOnlyStatus(() => true);
        add(possibleAnswers[keyPressed]['@id']);
      }
    }
});

AnswerSingleList.defaultProps = {
  answers: ''
};

const mapDispatchToProps = (dispatch) => ({
  add: (answer) => dispatch(addAnswer(answer))
});

const withReadOnly = withState('readonly', 'changeReadOnlyStatus', false);

const enhance = compose(
  connect(undefined, mapDispatchToProps, null, { pure: false }),
  withReadOnly,
  withEventsManagement,
  withKeyboardListener
);

AnswerSingleList.defaultProps = {
  possibleAnswers: []
};

export default enhance(AnswerSingleList);
