import counterpart from 'counterpart';

counterpart.registerTranslations('fr', {
  welcome: {
    title: 'Bonjour %(name)s',
    messageTrainingPre: `Les-Tilleuls.coop vous invite à participer à ce test dans le but d'évaluer votre niveau de connaissances
      en prévision de votre formation et de détecter l'éventuelle nécessité d'approfondir certaines notions.
      Vous pouvez naviguer entre les questions en utilisant les flèches de votre clavier et pouvez répondre aux questions
      en pressant les touches correspondantes ou en utilisant votre souris.`,
    messageTrainingPost: `Les-Tilleuls.coop vous invite à participer à ce test dans le but d'évaluer votre progression à
      la suite de votre formation effectuée en notre compagnie : cela permettra de détecter si les notions abordées sont
      maîtrisées et si certaines nécessitent d’être plus approfondies. Vous pouvez naviguer entre les questions en
      utilisant les flèches de votre clavier et pouvez répondre aux questions en pressant les touches correspondantes
      ou en utilisant votre souris.`,
    messageRecruiting: `Les-Tilleuls.coop vous invite à participer à ce test dans le but d'évaluer vos compétences en
      développement web,    des processus associés, mais également dans votre capacité à chercher sur Internet les
      réponses que vous ne connaîtriez pas. Ce test est chronométré mais pas de stress, nous souhaitons juste évaluer les
      domaines dans lesquels vous vous sentez le plus à l'aise et ceux qui pourraient nécessiter plus d’accompagnement
      de notre part.
      Vous pouvez naviguer entre les questions en utilisant les flèches de votre clavier et pouvez répondre aux questions
      en pressant les touches correspondantes ou en utilisant votre souris.`,
    callToAction: 'On démarre ?',
    messageHomePageHeader: `Bienvenue sur la plateforme de test Les-Tilleuls.coop.`,
    messageHomePageContent: `Consultez vos e-mails pour retrouver le lien de votre test personnalisé (pensez à regarder dans les spams !).
      Si vous n'avez pas reçu le lien de votre test ou que vous rencontrez un problème, `,
    messageHomePageContact: `contactez nous.`
  },
  question: {
    severalAnswers: '* réponses multiples',
    nextQuestion: 'Question suivante',
    yourCode: '// Écrivez votre code ici'
  },
  summary: {
    title: 'Récapitulatif de vos réponses',
    thanks: {
      title: `Merci d'avoir répondu à ce test.`,
      score: `Sur l'ensemble des questions fermées, vous avez obtenu un score de`,
      openingTrainingPre: `Ce résultat va nous permettre d'évaluer votre niveau avant la formation et de mesurer la progression
        de vos connaissances après celle-ci. Nous restons à votre disposition si vous avez la moindre question.
        Bonne journée.`,
      openingTrainingPost: ` Ce résultat, en addition de votre test pré-formation, va nous permettre de mesurer la progression
        de vos connaissances et de déceler, si besoin, l'approfondissement de certaines notions. Bonne journée.`,
      openingRecruiting: `Ce test nous permettra d'orienter les questions lors d'un éventuel futur entretien.
        Nous revenons vers vous au plus vite. Bonne journée.`,
      rgpd: `Conformément à la loi Informatique et libertés, vous disposez d'un droit d'accès, de rectification
        et de suppression des données vous concernant. Pour faire valoir vos droits, contactez-nous sur contact@les-tilleuls.coop`
    },
    edit: 'Modifier la réponse',
    submit: 'Valider ces réponses',
    rightAnswers: {
      one: 'La bonne réponse était: ',
      other: 'Les bonnes réponses étaient: '
    },
    levels: {
      hard: 'Difficile',
      easy: 'Facile',
      intermediate: 'Intermédiaire'
    },
    duration: 'Temps de réponse:'
  },
  error: {
    description:
      "Nous n'avons pu trouver de test correspondant à ces critères. Contactez-nous pour plus d'informations."
  }
});
