import React from 'react';
import { AppBar, Card, CardText } from 'material-ui';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import classnames from 'classnames';
import { addStartTime } from '../../actions/tests';
// eslint-disable-next-line import/no-cycle
import { AnswerSingleList, AnswerMultipleList } from './index';
import '../../../stylesheets/components/question.scss';
import EntityHelper from '../../lib/entityHelper';

const prepareForPrism = (code = '', language) => {
  return code
    .replace(/<pre>/gi, `<pre><code class="language-${language}">`)
    .replace(/<\/pre>/gi, '</code></pre>');
};

function Question({ active, answers, question, position }) {
  const answerProps = {
    active,
    answers,
    language: question.category.label.toLowerCase(),
    position,
    possibleAnswers: question.answers
  };

  return (
    <Card
      className={classnames('card question', `card-${question.type}`, {
        'card--active': active
      })}
    >
      <AppBar
        title={question.label + (question.type === 'multiple' ? ' *' : '')}
        showMenuIconButton={false}
        titleStyle={{ paddingTop: '10px', paddingBottom: '10px' }}
      />
      <CardText className="question-content">
        {question.help && (
          <div
            dangerouslySetInnerHTML={{
              __html: prepareForPrism(
                EntityHelper.encodePre(question.help),
                question.category.label.toLowerCase()
              )
            }}
          />
        )}
        {question.type === 'boolean' && <AnswerSingleList {...answerProps} />}
        {question.type === 'simple' && <AnswerSingleList {...answerProps} />}
        {question.type === 'multiple' && <AnswerMultipleList {...answerProps} />}
      </CardText>
    </Card>
  );
}

const withStartTimeInit = lifecycle({
  componentDidMount() {
    if (this.props.position === 1) {
      this.props.addStartTime();
    }
  }
});

const mapStateToProps = ({ tests }, { position }) => {
  const { results, currentQuestion, done } = tests.test;

  return {
    active: !done && position === currentQuestion,
    answers: results[position - 1] ? results[position - 1].answers : undefined
  };
};

const mapDispatchToProps = (dispatch) => ({
  addStartTime: () => dispatch(addStartTime())
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps, null, { pure: false }),
  withStartTimeInit
);

export default enhance(Question);
