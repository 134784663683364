import TestResource from '../resources/TestResource';

export const FETCHING_TEST = 'test/FETCHING';
export const FETCH_TEST_SUCCESS = 'test/FETCH_SUCCESS';
export const FETCH_TEST_ERROR = 'test/FETCH_ERROR';
export const TEST_PREV_QUESTION = 'test/PREV_QUESTION';
export const TEST_NEXT_QUESTION = 'test/NEXT_QUESTION';
export const TEST_GOTO_QUESTION = 'test/GOTO_QUESTION';
export const TEST_GOTO_SUMMARY = 'test/GOTO_SUMMARY';
export const TIME_ADD_START = 'time/ADD_START';
export const TIME_ADD_END = 'time/ADD_END';

export const TEST_ADD_ANSWER = 'test/ADD_ANSWER';

const fetching = () => ({ type: FETCHING_TEST });
const success = (payload) => ({ type: FETCH_TEST_SUCCESS, payload });
const error = (e) => ({ type: FETCH_TEST_ERROR, e });

export const addStartTime = () => ({ type: TIME_ADD_START });
const addEndTime = () => ({ type: TIME_ADD_END });

export const prevQuestion = () => (dispatch) => {
  dispatch(addEndTime());
  dispatch(addStartTime());
  return dispatch({ type: TEST_PREV_QUESTION });
};
export const nextQuestion = () => (dispatch, getState) => {
  dispatch(addEndTime());
  dispatch(addStartTime());

  const { questions, results } = getState().tests.test;

  if (questions && results && questions.length === results.length) {
    return dispatch({ type: TEST_GOTO_SUMMARY });
  }

  return dispatch({ type: TEST_NEXT_QUESTION });
};
export const gotoQuestion = (index) => (dispatch) => {
  dispatch(addEndTime());
  dispatch(addStartTime());
  return dispatch({ type: TEST_GOTO_QUESTION, index });
};
export const gotoSummary = (dispatch) => {
  dispatch(addEndTime());
  dispatch(addStartTime());
  return dispatch({ type: TEST_GOTO_SUMMARY });
};

export const findByHash = (hash) => {
  return (dispatch) => {
    dispatch(fetching());

    return TestResource.findByHash(hash)
      .then((payload) => dispatch(success(payload)))
      .catch((err) => dispatch(error(err)));
  };
};

export const addAnswer = (answer, goToNextQuestion = true) => {
  return (dispatch) => {
    dispatch({ type: TEST_ADD_ANSWER, answer });

    if (goToNextQuestion === true) {
      return new Promise((resolve) => {
        setTimeout(() => {
          dispatch(nextQuestion());
          resolve();
        }, 200);
      });
    }
  };
};

export const saveResults = (results) => {
  return (dispatch) => {
    TestResource.saveResults(results)
      .then((payload) => dispatch(success(payload)))
      .catch((err) => dispatch(error(err)));
  };
};
