import React from 'react';
import ReactDOM from 'react-dom';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { red600, brown300, brown500, brown700 } from 'material-ui/styles/colors';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import counterpart from 'counterpart';
import { BrowserRouter } from 'react-router-dom';
import App from './app/components/App';
import reducers from './app/reducers';
import './translations/fr';
import './stylesheets/main.scss';

// sets default locale
// const counterpart = new Counterpart.Instance();
counterpart.setLocale('fr');
counterpart.setFallbackLocale('en');

// Customize theme
const muiTheme = getMuiTheme({
  palette: {
    primary1Color: '#ee4322',
    primary2Color: red600,
    accent1Color: brown700,
    accent2Color: brown300,
    accent3Color: brown500
  }
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

// sets router strategy
const supportsHistory = 'pushState' in window.history;

ReactDOM.render(
  <BrowserRouter forceRefresh={!supportsHistory}>
    <Provider store={store}>
      <MuiThemeProvider muiTheme={muiTheme}>
        <App />
      </MuiThemeProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);
