import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
// eslint-disable-next-line import/no-cycle
import { Question } from './index';
import { nextQuestion } from '../../actions/tests';

function QuestionList({ questions }) {
  return (
    <div className="question-list">
      {questions.map((question, index) => (
        <Question question={question} key={question.label} position={index + 1} />
      ))}
    </div>
  );
}

QuestionList.defaultProps = {
  questions: []
};

const mapStateToProps = ({ tests }) => ({
  questions: tests.test.questions
});

const mapDispatchToProps = (dispatch) => ({
  next: () => dispatch(nextQuestion())
});

const withNextOnMount = lifecycle({
  componentDidMount() {
    setTimeout(() => this.props.next(), 100);
  }
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withNextOnMount);

export default enhance(QuestionList);
