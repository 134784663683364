import {} from 'react-router-dom';

const animatedTransition = (animation, duration, transition) => {
  new Promise((resolve) => {
    animation();

    setTimeout(() => resolve(), duration);
  }).then(() => transition());
};

export default animatedTransition;
