import React from 'react';
import counterpart from 'counterpart';
import Translate from 'react-translate-component';
import { Card, CardActions, CardText, RaisedButton } from 'material-ui';
import PlayArrow from 'material-ui/svg-icons/av/play-arrow';
import { connect } from 'react-redux';
import {
  branch,
  compose,
  lifecycle,
  renderComponent,
  renderNothing,
  withHandlers,
  withState
} from 'recompose';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import Loading from '../global/Loading';
import animatedTransition from '../../lib/animatedTransition';
import '../../../stylesheets/components/welcomeMessage.scss';

function WelcomeMessage({ active, gotoQuizz, username, recruiting, trainingPost, trainingPre }) {
  return (
    <Card
      className={classnames('card welcome-message', {
        'card--active': active
      })}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1197.2 169.48"
        className="logo"
        role="img"
        aria-label="Les-Tilleuls.coop logo"
      >
        <g id="Logo" data-name="Logo">
          <path className="fill-current" d="M385.53,96.15h40v15h-40Z" />
          <path className="fill-current" d="M505.53,139.48h-20V117.23c0-12,8.31-21.78,20-24.41Z" />
          <path
            className="fill-current"
            d="M745.53,137.09a109.69,109.69,0,0,1-13.16,2.78,108.38,108.38,0,0,1-16.93,1.28,34.43,34.43,0,0,1-14-2.44,22.73,22.73,0,0,1-9.48-6.83,26.82,26.82,0,0,1-5-10.48,56.58,56.58,0,0,1-1.48-13.4V67.82h20v37.74q0,9.75,2.56,14.08c1.71,2.89,3.89,4.33,8.56,4.33,1.43,0,3-.07,4.58-.21s3-.29,4.3-.47V67.82h20Z"
          />
          <path
            d="M1054.11,136.58a33.66,33.66,0,0,1-13.62-13.07,38.72,38.72,0,0,1-5-19.86,38.34,38.34,0,0,1,5-19.79,33.94,33.94,0,0,1,13.74-13.14,44.17,44.17,0,0,1,39.13,0,33.9,33.9,0,0,1,13.75,13.14,38.33,38.33,0,0,1,5,19.79,38,38,0,0,1-5.09,19.8,34.27,34.27,0,0,1-13.88,13.13,41.86,41.86,0,0,1-19.63,4.57A41.06,41.06,0,0,1,1054.11,136.58Zm30.53-19.47q4.56-4.69,4.56-13.46t-4.43-13.46a15.14,15.14,0,0,0-21.87-.06q-4.37,4.64-4.37,13.52t4.3,13.46a13.94,13.94,0,0,0,10.77,4.7A14.79,14.79,0,0,0,1084.64,117.11Z"
            className="fill-primary"
          />
          <path
            d="M969.11,136.58a33.66,33.66,0,0,1-13.62-13.07,38.72,38.72,0,0,1-5-19.86,38.34,38.34,0,0,1,5-19.79A33.94,33.94,0,0,1,969.3,70.72a44.17,44.17,0,0,1,39.13,0,33.9,33.9,0,0,1,13.75,13.14,38.33,38.33,0,0,1,5,19.79,38,38,0,0,1-5.09,19.8,34.27,34.27,0,0,1-13.88,13.13,41.86,41.86,0,0,1-19.63,4.57A41.06,41.06,0,0,1,969.11,136.58Zm30.53-19.47q4.56-4.69,4.56-13.46t-4.43-13.46a15.14,15.14,0,0,0-21.87-.06q-4.36,4.64-4.37,13.52t4.3,13.46a13.94,13.94,0,0,0,10.77,4.7A14.79,14.79,0,0,0,999.64,117.11Z"
            className="fill-primary"
          />
          <path
            d="M945.13,129.56A46.79,46.79,0,0,1,940.36,118a14.7,14.7,0,0,1-10.09,3.8,13.94,13.94,0,0,1-10.77-4.7q-4.31-4.69-4.3-13.46t4.36-13.52a15.17,15.17,0,0,1,20.87-.92,47,47,0,0,1,4.78-11.43,47.81,47.81,0,0,1,5-7,.57.57,0,0,1-.13-.08,44.17,44.17,0,0,0-39.13,0,33.88,33.88,0,0,0-13.74,13.14,38.24,38.24,0,0,0-5,19.79,38.62,38.62,0,0,0,5,19.86,33.64,33.64,0,0,0,13.61,13.07,41.08,41.08,0,0,0,19.5,4.57,41.89,41.89,0,0,0,19.63-4.57l.17-.1A47.31,47.31,0,0,1,945.13,129.56Z"
            className="fill-primary"
          />
          <path
            className="fill-current"
            d="M233.87,119.48h-35v-100c-12.51,4-20,16.13-20,29.34v90.66h55Z"
          />
          <path
            className="fill-current"
            d="M410.53,19.48v20h35v100h20v-100h18.34a20,20,0,0,0,20-20H410.53Z"
          />
          <path
            className="fill-current"
            d="M668,136.65a28.35,28.35,0,0,1-4.33,1.62,54.09,54.09,0,0,1-6,1.42c-2.22.41-4.58.75-7.11,1a71.3,71.3,0,0,1-7.58.41,44.76,44.76,0,0,1-16.71-2.85,32.42,32.42,0,0,1-11.77-7.78,30.89,30.89,0,0,1-6.9-11.7,46.32,46.32,0,0,1-2.23-14.62,43.29,43.29,0,0,1,2.91-16.57,35.33,35.33,0,0,1,7.64-11.84,31.76,31.76,0,0,1,10.89-7.17,34,34,0,0,1,12.65-2.44q15.16,0,24,9.27t8.79,27.27c0,1.17-.05,2.46-.13,3.85s-.19,2.64-.27,3.72H626.06a13.38,13.38,0,0,0,5.82,9.88c3.42,2.44,8,3.66,13.8,3.66a57.91,57.91,0,0,0,10.89-1c1.12-.21,2.17-.44,3.15-.68ZM652.72,96.26a21.57,21.57,0,0,0-.88-5,12.61,12.61,0,0,0-2.3-4.33,12.32,12.32,0,0,0-3.93-3.12,12.8,12.8,0,0,0-5.88-1.21,13.46,13.46,0,0,0-5.82,1.15,11.88,11.88,0,0,0-4.06,3,13.83,13.83,0,0,0-2.51,4.4,29.42,29.42,0,0,0-1.28,5.07Z"
          />
          <path
            className="fill-current"
            d="M371.13,105.81a26.32,26.32,0,0,0-6.83-5.15,83.55,83.55,0,0,0-11-4.85c-2.17-.81-3.95-1.57-5.35-2.25a22.29,22.29,0,0,1-3.31-1.9,4.61,4.61,0,0,1-1.63-1.83,5.44,5.44,0,0,1-.4-2.16q0-5.28,9.47-5.28a41.68,41.68,0,0,1,9.27,1,73.68,73.68,0,0,1,7.14,2V68.54q-2.4-.65-5.45-1.24a66.1,66.1,0,0,0-12.45-1.15q-13,0-20.43,5.82T322.76,87.8a23.38,23.38,0,0,0,1.48,8.8,18.43,18.43,0,0,0,4.2,6.29,25.09,25.09,0,0,0,6.56,4.53,88.43,88.43,0,0,0,8.59,3.65,57.2,57.2,0,0,1,9.07,4.13c2,1.22,3,2.64,3,4.27q0,3.11-2.3,4.19c-1.54.72-4.15,1.08-7.85,1.08a51,51,0,0,1-10.69-1.15q-2.87-.6-5.65-1.46l-8.29,14.66a63.65,63.65,0,0,0,8.73,2.7,64.42,64.42,0,0,0,15.76,1.63q14.47,0,22.4-5.4t7.91-15.94a27.15,27.15,0,0,0-1-7.83A15.91,15.91,0,0,0,371.13,105.81Z"
          />
          <path
            className="fill-current"
            d="M852.67,105.84a26.22,26.22,0,0,0-6.84-5.15,81.18,81.18,0,0,0-11-4.84q-3.24-1.23-5.34-2.25a22.39,22.39,0,0,1-3.32-1.91,4.57,4.57,0,0,1-1.62-1.82,5.49,5.49,0,0,1-.41-2.17q0-5.28,9.48-5.28a41.59,41.59,0,0,1,9.26,1c2.57.59,4.94,1.27,7.15,2V68.57c-1.61-.43-3.42-.84-5.45-1.23a65.28,65.28,0,0,0-12.45-1.15q-13,0-20.44,5.82t-7.44,15.83a23.28,23.28,0,0,0,1.49,8.79,18.1,18.1,0,0,0,4.2,6.29,25.35,25.35,0,0,0,6.56,4.54,92.51,92.51,0,0,0,8.59,3.65,57.2,57.2,0,0,1,9.07,4.13q3,1.83,3,4.26c0,2.08-.76,3.47-2.3,4.19s-4.15,1.09-7.85,1.09a51,51,0,0,1-10.68-1.15c-1.91-.41-3.8-.89-5.65-1.46l-8.29,14.65a63,63,0,0,0,24.49,4.33q14.48,0,22.39-5.4t7.92-15.93a27.5,27.5,0,0,0-1-7.83A16,16,0,0,0,852.67,105.84Z"
          />
          <path
            className="fill-current"
            d="M553.72,141.15a50.05,50.05,0,0,1-14.16-1.88,20.87,20.87,0,0,1-8.59-4.9,16.25,16.25,0,0,1-4.3-7.65,41.23,41.23,0,0,1-1.14-10.13V39.48h20v73.08a26.14,26.14,0,0,0,.41,4.83,7.55,7.55,0,0,0,4.83,6,17.9,17.9,0,0,0,5.77,1.21Z"
          />
          <path
            className="fill-current"
            d="M593.72,141.15a50.05,50.05,0,0,1-14.16-1.88,20.87,20.87,0,0,1-8.59-4.9,16.25,16.25,0,0,1-4.3-7.65,41.23,41.23,0,0,1-1.14-10.13V39.48h20v73.08a26.14,26.14,0,0,0,.41,4.83,7.55,7.55,0,0,0,4.83,6,17.9,17.9,0,0,0,5.77,1.21Z"
          />
          <path
            className="fill-current"
            d="M793.72,141.15a50.05,50.05,0,0,1-14.16-1.88,20.87,20.87,0,0,1-8.59-4.9,16.25,16.25,0,0,1-4.3-7.65,41.23,41.23,0,0,1-1.14-10.13V39.48h20v73.08a26.14,26.14,0,0,0,.41,4.83,7.55,7.55,0,0,0,4.83,6,17.9,17.9,0,0,0,5.77,1.21Z"
          />
          <path
            d="M507.2,72.82q0,5.7-3.57,8.68a12.48,12.48,0,0,1-16.19,0q-3.57-3-3.57-8.68t3.57-8.69a12.48,12.48,0,0,1,16.19,0Q507.2,67.12,507.2,72.82Z"
            className="fill-primary"
          />
          <path
            d="M887.2,129.48q0,5.72-3.57,8.69a12.48,12.48,0,0,1-16.19,0q-3.57-3-3.57-8.69t3.57-8.68a12.48,12.48,0,0,1,16.19,0Q887.2,123.77,887.2,129.48Z"
            className="fill-primary"
          />
          <path
            d="M1192.18,83.86a33.9,33.9,0,0,0-13.75-13.14,44.17,44.17,0,0,0-39.13,0,33.94,33.94,0,0,0-13.74,13.14,38,38,0,0,0-5,19h0v66.66h20v-32.2a44.29,44.29,0,0,0,37.7-.7,34.27,34.27,0,0,0,13.88-13.13,38,38,0,0,0,5.09-19.8A38.33,38.33,0,0,0,1192.18,83.86Zm-22.54,33.25a14.79,14.79,0,0,1-11,4.7,13.94,13.94,0,0,1-10.77-4.7q-4.29-4.69-4.3-13.46t4.37-13.52a15.14,15.14,0,0,1,21.87.06q4.43,4.71,4.43,13.46T1169.64,117.11Z"
            className="fill-primary"
          />
          <path
            className="fill-current"
            d="M307.26,136.87a28,28,0,0,1-4.33,1.62,53.57,53.57,0,0,1-6,1.42q-3.32.61-7.1,1a71.44,71.44,0,0,1-7.58.41,44.69,44.69,0,0,1-16.71-2.85,32.46,32.46,0,0,1-11.78-7.78,30.89,30.89,0,0,1-6.9-11.7,46.32,46.32,0,0,1-2.23-14.62,43.48,43.48,0,0,1,2.91-16.57A35.64,35.64,0,0,1,255.16,76a31.71,31.71,0,0,1,10.9-7.17,34,34,0,0,1,12.65-2.44q15.15,0,24,9.27t8.79,27.27c0,1.17,0,2.46-.13,3.85s-.18,2.64-.27,3.72H265.31a13.38,13.38,0,0,0,5.82,9.88q5.14,3.66,13.8,3.66a58.1,58.1,0,0,0,10.9-1c1.11-.21,2.16-.44,3.15-.68ZM292,96.48a20.56,20.56,0,0,0-.88-5A12.17,12.17,0,0,0,284.87,84,12.85,12.85,0,0,0,279,82.82,13.49,13.49,0,0,0,273.16,84a12,12,0,0,0-4.06,3,13.62,13.62,0,0,0-2.5,4.4,28.57,28.57,0,0,0-1.29,5.07Z"
          />
          <path
            d="M84.52,75.41A29.6,29.6,0,0,1,56.34,51.8a14.7,14.7,0,0,1-2.67-1.06A12.22,12.22,0,0,1,48.78,46,13.93,13.93,0,0,1,47,38.89a13.88,13.88,0,0,1,1.81-7.12,12.22,12.22,0,0,1,5-4.72,15.91,15.91,0,0,1,14.07,0,12.2,12.2,0,0,1,4.94,4.72,13.78,13.78,0,0,1,1.81,7.12A13.65,13.65,0,0,1,72.74,46a12.28,12.28,0,0,1-5,4.73,14.93,14.93,0,0,1-3.07,1.16A21.53,21.53,0,0,0,84.52,67.34V36.49c-.35-.15-.71-.31-1-.49a12.11,12.11,0,0,1-4.9-4.7,14,14,0,0,1-1.78-7.14A13.73,13.73,0,0,1,78.6,17a12.16,12.16,0,0,1,4.94-4.73,15.91,15.91,0,0,1,14.07,0,12.18,12.18,0,0,1,5,4.73,13.83,13.83,0,0,1,1.81,7.12,13.7,13.7,0,0,1-1.83,7.12,12.35,12.35,0,0,1-5,4.72c-.29.15-.6.3-.91.43v45a31,31,0,0,0,19.24-23.68,13.72,13.72,0,0,1-2.31-1,12,12,0,0,1-4.89-4.7A13.89,13.89,0,0,1,106.88,45a13.73,13.73,0,0,1,1.81-7.12,12.13,12.13,0,0,1,4.94-4.72,15.84,15.84,0,0,1,14.08,0,12.13,12.13,0,0,1,4.94,4.72A13.73,13.73,0,0,1,134.46,45a13.67,13.67,0,0,1-1.83,7.12,12.32,12.32,0,0,1-5,4.72A14,14,0,0,1,124,58.08,39.15,39.15,0,0,1,96.63,90v11.74A55.14,55.14,0,0,0,140,71.26V33.7A33.69,33.69,0,0,0,106.26,0H62.06a55,55,0,0,0,10.5,99v13.52A67.87,67.87,0,0,1,41.63,0H33.7A33.7,33.7,0,0,0,0,33.7v72.56A33.69,33.69,0,0,0,33.7,140H84.52Z"
            className="fill-primary"
          />
          <path
            d="M126.19,45a6.81,6.81,0,0,0-1.6-4.84,5.46,5.46,0,0,0-7.87,0A6.9,6.9,0,0,0,115.16,45a6.93,6.93,0,0,0,1.54,4.84,5,5,0,0,0,3.88,1.69,5.33,5.33,0,0,0,4-1.69A6.69,6.69,0,0,0,126.19,45Z"
            className="fill-primary"
          />
          <path
            d="M96.63,114.69V140h9.63A33.69,33.69,0,0,0,140,106.26V93.61A67.78,67.78,0,0,1,96.63,114.69Z"
            className="fill-primary"
          />
          <path
            d="M60.78,32.36A5.18,5.18,0,0,0,56.84,34a6.81,6.81,0,0,0-1.57,4.86,6.91,6.91,0,0,0,1.54,4.84,5,5,0,0,0,3.88,1.7,5.34,5.34,0,0,0,4-1.7,6.67,6.67,0,0,0,1.64-4.84,6.8,6.8,0,0,0-1.59-4.84A5.18,5.18,0,0,0,60.78,32.36Z"
            className="fill-primary"
          />
          <path
            d="M90.58,17.62a5.2,5.2,0,0,0-4,1.67,6.89,6.89,0,0,0-1.57,4.87A6.93,6.93,0,0,0,86.61,29a5,5,0,0,0,3.87,1.69,5.3,5.3,0,0,0,4-1.69,6.65,6.65,0,0,0,1.64-4.84,6.83,6.83,0,0,0-1.59-4.85A5.2,5.2,0,0,0,90.58,17.62Z"
            className="fill-primary"
          />
        </g>
      </svg>
      {username ? (
        <>
          <CardText>
            <Translate content="welcome.title" with={{ name: username }} component="p" />
            {recruiting && <Translate content="welcome.messageRecruiting" component="p" />}
            {trainingPre && <Translate content="welcome.messageTrainingPre" component="p" />}
            {trainingPost && <Translate content="welcome.messageTrainingPost" component="p" />}
          </CardText>
          <CardActions className="align-right">
            <RaisedButton
              label={counterpart.translate('welcome.callToAction')}
              labelPosition="before"
              icon={<PlayArrow />}
              labelStyle={{ verticalAlign: 'middle' }}
              onClick={gotoQuizz}
              primary
            />
          </CardActions>
        </>
      ) : (
        <CardText>
          <Translate content="welcome.messageHomePageHeader" component="p" />
          <p>
            <Translate content="welcome.messageHomePageContent" />
            <a href="mailto:contact@les-tilleuls.coop">
              <Translate content="welcome.messageHomePageContact" />
            </a>
          </p>
        </CardText>
      )}
    </Card>
  );
}

const WelcomeMessageMounted = lifecycle({
  componentDidMount() {
    if (this.props.done) {
      this.props.gotoQuizz();
    } else if (typeof this.props.username || this.props.done !== 'undefined') {
      // force async props
      setTimeout(() => this.props.toggleActive(() => true));
    }
  }
})(WelcomeMessage);

const Content = branch(
  ({ fetching }) => fetching,
  renderComponent(Loading),
  renderComponent(WelcomeMessageMounted)
)(renderNothing());

const withTransitionToQuizz = withHandlers({
  gotoQuizz:
    ({ history, location, toggleActive }) =>
    () => {
      animatedTransition(
        () => toggleActive(() => false),
        250,
        () => history.replace(`/quizz${location.hash}`)
      );
    }
});

const toggleActive = withState('active', 'toggleActive', false);
const togglePast = withState('past', 'togglePast', false);

const mapStateToProps = ({ tests }) => ({
  done: tests.test.done,
  recruiting: tests.test.recruiting,
  trainingPre: tests.test.training && tests.test.previous_test_instance_id === null,
  trainingPost: tests.test.training && tests.test.previous_test_instance_id !== null,
  fetching: tests.fetching,
  username: tests.test.candidateName
});

const enhance = compose(
  connect(mapStateToProps),
  toggleActive,
  togglePast,
  withRouter,
  withTransitionToQuizz
);

export default enhance(Content);
