import { AllHtmlEntities } from 'html-entities';

const entities = new AllHtmlEntities();

const EntityHelper = {
  /**
   * Encodes the preformatted code of a string
   *
   * @param {string} str
   * @returns {string}
   */
  encodePre: (str) => {
    return (
      str
        .replace(/\t/gim, '  ')
        // eslint-disable-next-line
        .replace(/<pre>([<>\/a-z0-9\-\. \s"=]*)<\/pre>/gim, (matches, p1) => {
          return `<pre>${entities.encode(p1)}</pre>`;
        })
    );
  },

  /**
   * Decodes HTML entities of a string
   *
   * @param {string} str
   * @returns {string}
   */
  decode: (str) => {
    return entities.decode(str);
  }
};

export default EntityHelper;
