import React from 'react';
import { connect } from 'react-redux';
import { branch, compose, lifecycle, renderComponent, renderNothing, withState } from 'recompose';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import Loading from '../global/Loading';
import '../../../stylesheets/components/progression.scss';

function ProgressBar({ active, progression }) {
  return (
    <div className={classnames('progression', { 'progression--active': active })}>
      <div className="progression-bar">
        <FontAwesomeIcon
          icon={faCircle}
          className={`progression-dot progression-dot--25 fa-2x ${
            progression >= 25 ? 'progression-dot--passed' : ''
          }`}
        />
        <FontAwesomeIcon
          icon={faCircle}
          className={`progression-dot progression-dot--50 fa-2x ${
            progression >= 50 ? 'progression-dot--passed' : ''
          }`}
        />
        <FontAwesomeIcon
          icon={faCircle}
          className={`progression-dot progression-dot--75 fa-2x ${
            progression >= 75 ? 'progression-dot--passed' : ''
          }`}
        />
        <div
          className="progression-bar-status"
          style={{ height: `${progression ? progression - 1 : 0}%` }}
        />
        <p className="progression-bar-label" style={{ top: `${progression}%` }}>
          <FontAwesomeIcon icon={faCircle} />
          {progression} %
        </p>
      </div>
    </div>
  );
}

const withToggleActive = withState('active', 'toggleActive', false);
const activeOnMount = lifecycle({
  componentDidMount() {
    if (!this.props.done) {
      setTimeout(() => {
        this.props.toggleActive(() => true);
      }, 100);
    }
  },
  componentDidUpdate(oldProps) {
    if (oldProps.done !== this.props.done && this.props.done) {
      this.props.toggleActive(() => false);
    }
  }
});

const enhance = compose(withToggleActive, activeOnMount);

const MountedProgressBar = enhance(ProgressBar);

const Content = branch(
  ({ fetching }) => fetching,
  renderComponent(Loading),
  renderComponent(MountedProgressBar)
)(renderNothing());

const mapStateToProps = ({ tests }) => ({
  done: tests.test.done,
  fetching: tests.fetching,
  progression: Array.isArray(tests.test.questions)
    ? Math.round(((tests.test.currentQuestion - 1) * 100) / tests.test.questions.length)
    : 0
});

export default connect(mapStateToProps)(Content);
