import React from 'react';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { withRouter } from 'react-router-dom';
import { findByHash } from '../actions/tests';
import Routes from './global/Routes';

function App() {
  return (
    <div className="app-container">
      <Routes />
    </div>
  );
}

const withFetch = lifecycle({
  componentWillMount() {
    const { fetch, location } = this.props;

    if (location.hash !== '') {
      fetch(location.hash.substr(1));
    }
  },
  componentDidUpdate() {
    if (this.props.errored) {
      this.props.history.push('/404');
    }
  }
});

const mapStateToProps = ({ tests }) => ({
  fetching: tests.fetching,
  test: tests.test,
  errored: tests.errored
});

const mapDispatchToProps = (dispatch) => ({
  fetch: (hash) => dispatch(findByHash(hash))
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps, null, { pure: false }),
  withRouter,
  withFetch
);

export default enhance(App);
