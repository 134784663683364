import {
  FETCHING_TEST,
  FETCH_TEST_SUCCESS,
  FETCH_TEST_ERROR,
  TEST_ADD_ANSWER,
  TEST_PREV_QUESTION,
  TEST_NEXT_QUESTION,
  TEST_GOTO_QUESTION,
  TEST_GOTO_SUMMARY,
  TIME_ADD_END,
  TIME_ADD_START
} from '../actions/tests';

// -----------------------------------------------------------------------------
// PRIVATES

const defaultState = {
  errored: false,
  fetching: false,
  timer: 0,
  test: {
    currentQuestion: 0,
    results: []
  }
};

// -----------------------------------------------------------------------------
// REDUCER

function testsReducer(state = defaultState, action) {
  let question;
  let newAnswers;

  switch (action.type) {
    case FETCH_TEST_ERROR:
      return {
        ...state,
        fetching: false,
        errored: true
      };

    case FETCHING_TEST:
      return {
        ...state,
        fetching: true,
        test: {
          ...defaultState.test
        },
        errored: false
      };

    case FETCH_TEST_SUCCESS:
      return {
        ...state,
        fetching: false,
        test: {
          ...state.test,
          ...action.payload
        },
        errored: false
      };

    case TEST_PREV_QUESTION:
      return {
        ...state,
        test: {
          ...state.test,
          currentQuestion: state.test.currentQuestion - +(state.test.currentQuestion > 1)
        }
      };

    case TEST_NEXT_QUESTION:
      if (state.test.currentQuestion < 1) {
        return {
          ...state,
          test: {
            ...state.test,
            currentQuestion: 1
          }
        };
      }

      if (
        state.test.results.length < state.test.currentQuestion ||
        typeof state.test.results[state.test.currentQuestion - 1].answers === 'undefined' ||
        (state.test.questions[state.test.currentQuestion - 1].type === 'multiple' &&
          state.test.results[state.test.currentQuestion - 1].answers.length < 2)
      ) {
        return state;
      }

      return {
        ...state,
        test: {
          ...state.test,
          currentQuestion:
            state.test.currentQuestion +
            +(state.test.currentQuestion <= state.test.questions.length)
        }
      };

    case TEST_GOTO_QUESTION:
      return {
        ...state,
        test: {
          ...state.test,
          currentQuestion: action.index
        }
      };

    case TEST_GOTO_SUMMARY:
      return {
        ...state,
        test: {
          ...state.test,
          currentQuestion: state.test.questions.length + 1
        }
      };

    case TEST_ADD_ANSWER:
      question = state.test.currentQuestion - 1;
      newAnswers = [...state.test.results];
      newAnswers[question] = {
        ...newAnswers[question],
        answers: action.answer
      };

      return {
        ...state,
        test: {
          ...state.test,
          results: newAnswers
        }
      };

    case TIME_ADD_START:
      return {
        ...state,
        timer: new Date()
      };

    case TIME_ADD_END: {
      question = state.test.currentQuestion - 1;
      if (question < 0 || question === state.test.questions.length) {
        return state;
      }

      newAnswers = [...state.test.results];
      const timeDiff = new Date() - state.timer;

      if (
        typeof newAnswers[question] === 'undefined' ||
        typeof newAnswers[question].duration === 'undefined'
      ) {
        newAnswers[question] = {
          ...newAnswers[question],
          duration: timeDiff
        };
      } else {
        newAnswers[question] = {
          ...newAnswers[question],
          duration: newAnswers[question].duration + timeDiff
        };
      }
      newAnswers[question] = {
        ...newAnswers[question]
      };

      return {
        ...state,
        test: {
          ...state.test,
          results: newAnswers
        }
      };
    }

    default:
      return state;
  }
}

export default testsReducer;
